import { removeTrapFocus, trapFocus, debounce } from '../js/global-exports.js';

class CartNotification extends HTMLElement {
  constructor() {
    super();

    this.notification = document.getElementById('cart-notification');
    this.hasBeenShown = false;
    this.header = document.querySelector('sticky-header');
    this.onBodyClick = this.handleBodyClick.bind(this);

    this.notification.addEventListener('keyup', (evt) => evt.code === 'Escape' && this.close());
    this.querySelectorAll('button[type="button"]').forEach((closeButton) =>
      closeButton.addEventListener('click', this.close.bind(this))
    );
  }

  setPositionProperty() {
    if(window.isB2cSite === true) {
      this.pageHeaderSection = this.pageHeaderSection || document.getElementById('shopify-section-header');
      let cartNotificationPosition = 0;
      if(this.pageHeaderSection.getBoundingClientRect().top >= 0) {
        cartNotificationPosition = this.pageHeaderSection.getBoundingClientRect().bottom;
      }
      this.borderOffset = this.borderOffset || document.querySelector('.header-wrapper').classList.contains('header-wrapper--border-bottom') ? 1 : 0;
      document.documentElement.style.setProperty('--cart-notification-position', `${parseInt(cartNotificationPosition + this.borderOffset)}px`);
	} else if(window.isB2bSite === true) {
      const headerHeight = document.getElementById('shopify-section-header').offsetHeight;
      const announcementHeight = document.getElementById('shopify-section-announcement-bar').offsetHeight;

      document.documentElement.style.setProperty('--cart-notification-position', `${headerHeight}px`);
      document.documentElement.style.setProperty('--cart-notification-position-absolute', `${headerHeight+announcementHeight}px`);
	}
  }

  open(multipleAdded) {
    const singleAddedHeader = document.getElementById('cart-notification__heading--products-added-1');
    const multiAddedHeader = document.getElementById('cart-notification__heading--products-added-multiple');
    let theHeaderToShow = singleAddedHeader;
    let theHeaderToHide = multiAddedHeader;
    if(multipleAdded) {
      theHeaderToShow = multiAddedHeader;
      theHeaderToHide = singleAddedHeader;
    }

    this.notification.setAttribute('aria-label',theHeaderToShow.innerText)
    theHeaderToHide.classList.add('hidden');
    theHeaderToHide.setAttribute('aria-hidden','true');
    theHeaderToShow.classList.remove('hidden');
    theHeaderToShow.removeAttribute('aria-hidden');

    this.notification.classList.add('animate', 'active');
    if(window.isB2cSite === true) {
      document.body.classList.add('body--cart-notification-open','overflow-hidden');
    } else if(window.isB2bSite === true) {
      document.body.classList.add('body--cart-notification-open');
    }
    this.setPositionProperty();

    this.notification.addEventListener('transitionend', () => {
      this.notification.focus();
      trapFocus(this.notification);
    }, { once: true });

    if(!this.hasBeenShown) {
      this.hasBeenShown = true;

      window.addEventListener('resize', debounce(() => {
        this.setPositionProperty();
      },150));
    }

    document.body.addEventListener('click', this.onBodyClick);

    if(window.isB2bSite === true) {
      // Close cart notification automatically after x milliseconds
      setTimeout(() => {
        this.fadeClose();
      }, 5000)
    }
  }

  fadeClose() {
    document.body.classList.add('body--cart-notification-open-fade');
    setTimeout(() => {
      this.close();
    }, 900)
  }

  close() {
    this.notification.classList.remove('active');
    if(window.isB2cSite === true) {
      document.body.classList.remove('body--cart-notification-open','overflow-hidden');
    } else if(window.isB2bSite === true) {
      document.body.classList.remove('body--cart-notification-open');
      document.body.classList.remove('body--cart-notification-open-fade');
    }

    document.body.removeEventListener('click', this.onBodyClick);
    if(window.isB2cSite === true) {
      removeTrapFocus(this.activeElement);
    } else if(window.isB2bSite === true) {
      document.activeElement.blur();
      removeTrapFocus(null);
    }
  }

  renderContents(parsedState, clearCart) {
    const isAddMultipleToCart = !parsedState.id;
    if(!isAddMultipleToCart) {
        this.productId = parsedState.id;
    }
    this.getSectionsToRender(isAddMultipleToCart).forEach((section => {
      document.getElementById(section.id).innerHTML =
      this.getSectionInnerHTML(parsedState.sections[section.id], section.selector);
    }));

    if(!clearCart) {
      if (this.header) this.header.reveal();
      this.open(isAddMultipleToCart);
    }
  }

  getSectionsToRender(isAddMultipleToCart) {
    let theSectionsToRender =
     [
      {
        id: 'cart-notification-button'
      },
      {
        id: 'cart-icon-bubble'
      }
    ];

    if(!isAddMultipleToCart) {
      theSectionsToRender.push({
        id: 'cart-notification-product',
        selector: `#cart-notification-product-${this.productId}`
      });
    }

    if(document.getElementById('free-shipping-calculation-section')) {
      theSectionsToRender.push({id: 'free-shipping-calculation-section'})
    }

    return theSectionsToRender;
  }

  getSectionInnerHTML(html, selector = '.shopify-section') {
    let htmlHolder = null;
    if(window.isB2bSite === true) {
      htmlHolder =  new DOMParser()
      .parseFromString(html, 'text/html')
      .querySelector(selector);
    }
    if(window.isB2cSite === true || htmlHolder) {
      return new DOMParser()
        .parseFromString(html, 'text/html')
        .querySelector(selector).innerHTML;
    }
  }

  handleBodyClick(evt) {
    const target = evt.target;
    if (target !== this.notification && !target.closest('.cart-notification')) {
      const disclosure = target.closest('details-disclosure');
      this.activeElement = disclosure ? disclosure.querySelector('summary') : null;
      this.close();
    }
  }

  setActiveElement(element) {
    this.activeElement = element;
  }
}

customElements.define('cart-notification', CartNotification);
